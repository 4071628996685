<template>
  <v-container fluid>

    <message-card v-for="lastMessage in messages"
                  :key="lastMessage.id"
                  show-form="true"
                  :lastMessage="lastMessage"
    >
    </message-card>
    <div class="text-center" v-if="loading">
      <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
      ></v-progress-circular>
    </div>
    <lazy-message-loader></lazy-message-loader>

  </v-container>

</template>

<script>
import MessageCard from "@/components/message/MessageCard";
import LazyMessageLoader from "@/components/conversations/LazyMessageLoader";
import {mapGetters, mapState} from "vuex";

export default {
  components: {
    MessageCard,
    LazyMessageLoader
  },
  computed: {
    ...mapGetters({
      messages: "messages/getSortedMessages",
    }),
    ...mapState("messages", ["loading"]),

  }
}
</script>

<style scoped>

</style>
